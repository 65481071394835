export interface TrackEventContext {
    userId?: string;
    tenantId?: string;
    siteId?: string;
    siteName?: string;
    reportId?: string;
    editionId?: string;
}
export interface TrackEventType {
    type: AnalyticsTrackEvent;
    context?: TrackEventContext;
    properties?: object;
}

export enum AnalyticsTrackEvent {
    // Reports Overview (Home)
    reportsOverviewViewed = 'Analytics - Reports Overview Viewed',

    // Report Edition
    reportEditionVisited = 'Analytics - Report Edition Visited',
    // Report Edition > Scrolling to below 50% of the page
    reportEditionViewed50 = 'Analytics - Report Edition Viewed 50',
    // Report Edition > Scrolling to the end of the page
    reportEditionViewed100 = 'Analytics - Report Edition Viewed 100',

    // Report Edition > Edit sharing > Adding a user for link email
    recipientsForLinkEmailAdded = 'Analytics - Recipients For Link Email Added',
    // Report Edition > Edit sharing > Removing a user for link email
    recipientsForLinkEmailRemoved = 'Analytics - Recipients For Link Email Removed',

    // Report Edition > Edit sharing > Adding a user for csv email
    recipientsForCsvAdded = 'Analytics - Recipients For Csv Email Added',
    // Report Edition > Edit sharing > Removing a user for csv email
    recipientsForCsvRemoved = 'Analytics - Recipients For Csv Email Removed',

    // Report Edition > Edit template > Add a table element (even if template changes are not saved!)
    tableElementAdded = 'Analytics - Table Element Added',
    // Report Edition > Edit template > Add a chart element (even if template changes are not saved!)
    chartElementAdded = 'Analytics - Chart Element Added',
    // Report Edition > Edit template > Add a scatter plot element (even if template changes are not saved!)
    scatterPlotElementAdded = 'Analytics - ScatterPlot Element Added',
    // Report Edition > Edit template > Add a KPI set element (even if template changes are not saved!)
    kpiSetElementAdded = 'Analytics - KPI Set Element Added',

    // Report Edition > Edit template > Edit a table (even if template changes are not saved!)
    tableElementUpdated = 'Analytics - Table Element Updated',
    // Report Edition > Edit template > Edit a chart (even if template changes are not saved!)
    chartElementUpdated = 'Analytics - Chart Element Updated',
    // Report Edition > Edit template > Edit a scatter plot (even if template changes are not saved!)
    scatterPlotElementUpdated = 'Analytics - ScatterPlot Element Updated',
    // Report Edition > Edit template > Edit a KPI set (even if template changes are not saved!)
    kpiSetElementUpdated = 'Analytics - KPI Set Element Updated',

    // Report Edition > Edit template > Delete a table (even if template changes are not saved!)
    tableElementDeleted = 'Analytics - Table Element Deleted',
    // Report Edition > Edit template > Delete a chart (even if template changes are not saved!)
    chartElementDeleted = 'Analytics - Chart Element Deleted',
    // Report Edition > Edit template > Delete a scatter plot (even if template changes are not saved!)
    scatterPlotElementDeleted = 'Analytics - ScatterPlot Element Deleted',
    // Report Edition > Edit template > Delete a KPI set (even if template changes are not saved!)
    kpiSetElementDeleted = 'Analytics - KPI Set Element Deleted',

    // Report Edition > Edit template > Add/edit a chart, table or scatter plot > Add Data
    datapointAdded = 'Analytics - Reports Data Point Added',
    // Report Edition > Edit template > Add/edit a chart or scatter plot > Add data point filter
    datapointFilterAdded = 'Analytics - Reports Data Point Filter Added',
    // Report Edition > Edit template > Add/edit a chart > Add bandwidth values
    bandwidthPropertyAdded = 'Analytics - Bandwidth Property Added',
    // Report Edition > Edit template > Add/edit a chart > Click 'Remove bandwidth' (even if you don't click Apply changes)
    bandwidthPropertyDeleted = 'Analytics - Bandwidth Property Deleted',

    // Report Edition > Edit template > Save changes button (only tracked when there are changes)
    templateSaved = 'Analytics - Template Saved',

    // Report Overview > Click 'New Report'
    newReportCreationStarted = 'Analytics - New Report Creation Started',
    // Report Overview > Click 'Duplicate' on a report
    duplicateReportCreationStarted = 'Analytics - Duplicate Report Creation Started',

    // Report Overview > New Report or Duplicate Report > Click 'Save & Edit Template'
    newReportCreated = 'Analytics - New Report Draft Created',

    // A Draft Report, Recurring > Activate
    reportActivated = 'Analytics - Report Activated',
    // A Draft Report, One-off > Publish
    reportPublished = 'Analytics - Report Published',
}
